<template>
  <div :class="[{ 'd-none': !modalVisible }]">
    <div
      v-if="modalVisible"
      ref="modal"
      :class="['modal fade m-modal', modalCssClass]"
      :data-autofocus="autofocus ? null : 'false'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div
        ref="dialogEl"
        class="modal-dialog"
        role="document"
      >
        <div :class="['modal-content', {'with-subtitle': hasSubtitle}]">
          <div class="modal-header">
            <slot name="modal-header">
              <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
              <h4 class="modal-title"><%= t '.folder_modal.title_create' %></h4> -->
              <h5
                id="modalLabel"
                class="modal-title"
              >
                {{ modalTitle }}
              </h5>
            </slot>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div
            v-if="hasSubtitle"
            class="modal-sub-title"
          >
            {{ modalSubtitle }}
          </div>
          <div class="modal-body">
            <loading-indicator
              v-if="isLoading"
              :message="loadingMessage"
            />
            <!-- <div v-if="error && showModalError" class="alert alert-danger" role="alert">{{ error }}</div> -->
            <div
              v-if="showErrorMessage"
              class="alert alert-danger"
            >
              {{ errorMessage }}
              <a
                v-if="hasErrorDetails && !errorDetailsVisible && !errorDetailsExpanded"
                href="#"
                class="float-right"
                @click.prevent="showErrorDetails"
              >
                <small>{{ $t('form_errors.view_details') }}</small>
              </a>
              <button
                v-else
                type="button"
                class="close"
                aria-label="Close"
                @click.prevent="hideError"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <template v-if="hasErrorDetails && (errorDetailsVisible || errorDetailsExpanded)">
                <hr>
                <ul>
                  <li
                    v-for="(message, index) of errorDetails"
                    :key="index"
                  >
                    {{ message }}
                  </li>
                </ul>
              </template>
            </div>
            <slot name="modal-content">
              <div
                v-if="formDetails && formDetails.html"
                ref="formWrap"
                v-html="formDetails.html"
              />
            </slot>
          </div>
          <div
            v-if="withFooter"
            class="modal-footer"
          >
            <slot name="modal-footer">
              <!-- <button v-if="deleteVisible" type="button" class="btn btn-sm btn-danger" @click="deleteItem">Delete</button> -->
              <button
                v-if="showSubmitButton"
                type="button"
                class="m-btn m-btn-primary"
                @click="submitAction"
              >
                {{ submitButtonTitle }}
              </button>
              <button
                v-if="readOnlyForm || (hasForm && formEl == null)"
                type="button"
                class="m-btn m-btn-cancel"
                data-dismiss="modal"
              >
                {{ $t('modal.close_button') }}
              </button>
              <button
                v-else
                type="button"
                class="m-btn m-btn-cancel"
                data-dismiss="modal"
              >
                {{ closeButtonTitle }}
              </button>
              <!-- <button v-if="showFormSubmit" type="button" @click="submitForm" class="btn btn-sm btn-primary" :disabled="isLoading">Save</button> -->
              <button
                v-if="withDeleteButton"
                class="m-btn m-btn-destroy ml-auto"
                @click.prevent="showDeleteConfirmation"
              >
                {{ $t('modal.delete_button') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modalVisible"
      class="modal-backdrop fade show"
    />
    <slot name="additional-content" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import $ from 'jquery';
  import { isPresent, isNonEmptyString } from '../js-utils'
  import { initializeFormFields, autofocusInputField } from '../site-utils'
  import axios from 'axios'
  import Rails from '@rails/ujs'
  import Jsona from 'jsona'
  import type { TJsonaModel } from 'jsona/lib/JsonaTypes'
  import type { ModalOptions } from '../types'
  import URI from 'urijs'
  import LoadingIndicator from './loading-indicator.vue'

  export interface DataRequestOptions {
    url: string;
    params?: any;
    requestMethod?: 'get' | 'GET' | 'post' | 'POST';
    jsonapiResponse: boolean;
  }

  // export default (Vue as VueConstructor<
  //   Vue & {
  //     $refs: {
  //       formWrap?: HTMLElement;
  //     };
  //   }
  // >).extend({
  export default defineComponent({
    components: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LoadingIndicator
    },
    props: {
      modalTitle: { default: '', type: String },
      modalSubtitle: { default: '', type: String },
      modalCssClass: { default: null, type: String },
      saveBtnTitle: { default: '', type: String },
      closeBtnTitle: { default: '', type: String },
      allowClose: { default: true, type: Boolean },
      allowKeyboardClose: { default: false, type: Boolean },
      autofocus: { default: true, type: Boolean },
      withFooter: { default: true, type: Boolean },
      errorDetailsExpanded: { default: false, type: Boolean },
    },
    data () {
      return {
        modalVisible: false,
        isLoading: false,
        loadingMessage: '',
        formUrl: null as string | null,
        formParams: null as any,
        formDetails: null as TJsonaModel | null,
        formEl: null as HTMLFormElement | null,
        readOnlyForm: false,
        formLoadRequestMethod: null as 'get' | 'post' | 'GET' | 'POST' | null,
        formLoadJsonapiRequest: false,
        errorMessage: null as string | null,
        errorDetails: [] as string[],
        errorDetailsVisible: false,
        shownEventName: 'shown.bs.modal',
        contextData: null as any,
      }
    },
    computed: {
      hasForm (): boolean {
        return isPresent(this.formUrl)
      },

      showSubmitButton (): boolean {
        if (this.readOnlyForm) {
          return false
        }

        if (this.formDetails && isPresent(this.formDetails.html) && this.formEl) {
          return true
        }
        return false
      },

      submitButtonTitle(): string {
        if (this.formDetails && isNonEmptyString(this.formDetails.submitTitle)) {
          return this.formDetails.submitTitle
        }
        if (isNonEmptyString(this.saveBtnTitle)) {
          return this.saveBtnTitle
        } else {
          return this.$t('modal.save_button')
        }
      },

      closeButtonTitle(): string {
        if (isNonEmptyString(this.closeBtnTitle)) {
          return this.closeBtnTitle
        } else {
          return this.$t('modal.cancel_button')
        }
      },

      showErrorMessage(): boolean {
        return isPresent(this.errorMessage)
      },

      hasErrorDetails(): boolean {
        return isPresent(this.errorMessage) && this.errorDetails.length > 0
      },

      hasSubtitle(): boolean {
        return isPresent(this.modalSubtitle)
      },

      deleteConfirmationUrl(): string | null {
        const deleteConfirmationUrl	= this.formDetails?.meta?.deleteConfirmationUrl
        if (isNonEmptyString(deleteConfirmationUrl)) {
          return deleteConfirmationUrl
        }
        return null
      },

      withDeleteButton(): boolean {
        return this.deleteConfirmationUrl != null
      }
    },
    // updated () {
    //   if (this.$refs.modal) {

    //   }
    // },
    methods: {
      showForm (formUrl: string, formParams?: any, modalOptions?: ModalOptions, contextData?: any): void {
        this.formUrl = formUrl
        this.formParams = formParams
        this.formLoadRequestMethod = null
        this.readOnlyForm = modalOptions?.readonly || false
        this.contextData = contextData
        if (modalOptions) {
          if (modalOptions.requestMethod) {
            this.formLoadRequestMethod = modalOptions.requestMethod
          }
          if (modalOptions.jsonapiRequest != null && typeof modalOptions.jsonapiRequest !== 'undefined') {
            this.formLoadJsonapiRequest = modalOptions.jsonapiRequest
          }
        }

        this.doShow()
      },
      clearForm (): void {
        this.formDetails = null
      },
      show (dataOptions?: DataRequestOptions): void {
        if (dataOptions && dataOptions.url.length > 0) {
          this.$once('modal-shown', () => {
            // perform data load
            this.loadData(dataOptions)
          })
        }
        this.doShow()
      },
      doShow (): void {
        if (!this.modalVisible) {
          this.modalVisible = true

          this.resetState()

          this.formDetails = null
          this.formEl = null

          // show modal on next render
          this.$nextTick(() => {
            $(this.$refs.modal).on('hidden.bs.modal', () => {
              this.modalVisible = false
              this.$emit('modal-hidden')
            })
            $(this.$refs.modal).on('shown.bs.modal', () => {
              this.$emit('modal-shown')

              if (this.hasForm) {
                this.loadForm()
              }
            })
            if (!this.allowClose) {
              $(this.$refs.modal).on('hide.bs.modal.prevent', (e) => {
                e.preventDefault()
                this.$emit('should-hide')
              })
            }

            // show modal
            $(this.$refs.modal).modal({
              backdrop: false,
              keyboard: this.allowKeyboardClose
            })

            // if (this.hasForm) {
            //   this.loadForm()
            // } else if (isPresent(this.dataUrl)) {
            //   this.loadData()
            // }
          })
        }
      },
      hide (): void {
        $(this.$refs.modal).off('hide.bs.modal.prevent')
        $(this.$refs.modal).modal('hide')
      },
      resetState (): void {
        this.hideError()
      },
      hideError (): void {
        this.errorMessage = null
        this.errorDetails = []
        this.errorDetailsVisible = false
      },
      loadForm (): void {
        if (this.formUrl) {
          let requestUrl = this.formUrl
          let requestData = this.formParams
          let requestMethod: 'get' | 'post' | 'GET' | 'POST' = requestData ? 'post' : 'get'
          if (this.formLoadRequestMethod) {
            requestMethod = this.formLoadRequestMethod
            if ((requestMethod === 'GET' || requestMethod === 'get') && requestData) {
              const uri = new URI(requestUrl)
              const urlParams: any = {}
              if (requestData.file_ids && Array.isArray(requestData.file_ids)) {
                urlParams['file_ids'] = requestData.file_ids.join(',')
              }
              uri.search(urlParams)
              requestUrl = uri.toString()
              requestData = null
            }
          }
          this.isLoading = true
          this.loadingMessage = this.$t('modal.loading_form')

          const requestHeaders: any = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Requested-With': 'XMLHttpRequest',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-CSRF-Token': Rails.csrfToken()
          }
          if (this.formLoadJsonapiRequest) {
            requestHeaders['Content-Type'] = 'application/vnd.api+json'
            requestHeaders['Accept'] = 'application/vnd.api+json'
          }

          axios(requestUrl, {
            method: requestMethod,
            data: requestData,
            headers: requestHeaders
          })
          .then((response) => {
            const dataFormatter = new Jsona()
            const formObject = dataFormatter.deserialize(response.data)
            if (formObject) {
              this.setupForm(formObject)
            }
          })
          .catch((error) => {
            let errorHandled = false
            try {
              if (error.response.data) {
                const dataFormatter = new Jsona()
                const formObject = dataFormatter.deserialize(error.response.data)
                if (!Array.isArray(formObject)) {
                  if (formObject && formObject.type == 'resource-form') {
                    this.setupForm(formObject)
                    errorHandled = true
                  }
                }
              }
            } catch {}
            if (!errorHandled) {
              this.errorMessage = 'An error occured while loading form'
              if (error && error.message) {
                this.errorDetails = [
                  error.message
                ]
              }
            }
          })
          .then(() => {
            // self.hideLoadingIndicator()
          })
          .then(() => {
            this.isLoading = false
          })
        }
      },
      setupForm (formData: TJsonaModel): void {
        this.formDetails = formData
        if (this.formDetails && this.formDetails.meta && this.formDetails.meta.error) {
          const err = this.formDetails.meta.error
          if (isPresent(err.message)) {
            this.errorMessage = err.message
            if (Array.isArray(err.details) && err.details.length > 0) {
              this.errorDetails = err.details
            }
          }
        }
        if (this.formDetails) {
          this.$emit('form-data-loaded', this.formDetails)
          this.$nextTick(() => {
            this.initForm()
          })
        }
        // this.deleteUrl = null
        // if (typeof responseData === 'string') {
        //   this.formHTML = responseData
        // } else {
        //   const status = responseData.status
        //   if (isPresent(status) && status === 'success') {
        //     // form operation succeeded
        //     this.$emit('success', responseData.candidate)
        //     return
        //   }
        //   this.formHTML = responseData.html
        //   if (isPresent(responseData.deleteUrl)) {
        //     this.deleteUrl = responseData.deleteUrl
        //   }
        // }

        // this.$nextTick(() => {
        //   this.attachToFormEvents()
        //   this.initForm()
        //   this.$emit('remoteFormLoaded')
        // })
      },

      initForm (): void {
        if (this.$refs.formWrap) {
          this.formEl = this.$refs.formWrap.querySelector(':scope form[ref="inputForm"]')

          if (this.formEl) {
            initializeFormFields(this.formEl)
            this.formEl.addEventListener('ajax:success', (e: any) => {
              const [data, _status, xhr] = e.detail
              const dataFormatter = new Jsona()
              const responseObject = dataFormatter.deserialize(data)
              if (xhr.status == 201) {
                this.$emit('created', responseObject, this.contextData)
              } else if (responseObject) {
                this.setupForm(responseObject)
              }
              // this.showLoadingIndicator(false)
            })

            this.formEl.addEventListener('ajax:error', (e: any) => {
              const [responseData, status, xhr] = e.detail
              let errorHandled = false
              if (xhr.status == 422) {
                const dataFormatter = new Jsona()
                const formObject = dataFormatter.deserialize(responseData)
                if (formObject) {
                  errorHandled = true
                  this.setupForm(formObject)
                }
              }
              if (!errorHandled) {
                alert(`Error: ${xhr.status} - ${status}`)
              }
            })

            this.$emit('form-loaded', this.formEl)
          } else {
            this.$emit('form-loaded', this.$refs.formWrap)
          }

          this.$nextTick(() => {
            if (this.$refs.formWrap && this.autofocus) {
              autofocusInputField(this.$refs.formWrap)
            }
          })
        }
      },

      submitAction (): void {
        this.resetState()
        if (this.formEl) {
          Rails.fire(this.formEl, 'submit')
        } else {
          alert('Form not initialized')
        }
      },

      showErrorDetails (): void {
        this.errorDetailsVisible = true
      },

      loadData (requestOptions: DataRequestOptions): void {
        if (requestOptions.url.length > 0) {
          const requestUrl = requestOptions.url
          const requestData = requestOptions.params
          const requestMethod: 'get' | 'GET' | 'post' | 'POST' = requestOptions.requestMethod || (requestData ? 'post' : 'get')

          this.isLoading = true
          this.loadingMessage = 'Loading data ...'
          axios(requestUrl, {
            method: requestMethod,
            data: requestData,
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'X-Requested-With': 'XMLHttpRequest',
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'X-CSRF-Token': Rails.csrfToken()
            }
          })
          .then((response) => {
            if (requestOptions.jsonapiResponse) {
              const dataFormatter = new Jsona()
              const responseObject = dataFormatter.deserialize(response.data)
              this.$emit('data-loaded', responseObject)
            } else {
              this.$emit('data-loaded', response.data)
            }
          })
          .catch((error) => {
            this.errorMessage = 'An error occured while loading data'
            if (error && error.message) {
              this.errorDetails = [
                error.message
              ]
            }
          })
          .then(() => {
            this.isLoading = false
          })
        }
      },

      showDeleteConfirmation(): void {
        this.$emit('delete-confirmation', this.deleteConfirmationUrl)
      }
    }
  })
</script>
