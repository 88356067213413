import type { Options as Select2Options, OptGroupData, OptionData, IdTextPair, SearchOptions} from 'select2'
import axios from 'axios'
import URI from 'urijs'
import '@chenfengyuan/datepicker'
import $ from 'jquery'

interface NewTag {
  id?: string;
  newTag?: boolean;
}

const autocompleteResultsSorter = function (data: Array<OptGroupData | OptionData | IdTextPair>): Array<OptGroupData | OptionData | IdTextPair> {
  data.sort(function (a: (OptGroupData | OptionData | IdTextPair) & NewTag, b: (OptGroupData | OptionData | IdTextPair) & NewTag): number {
    // move tags to the bottom of the results, leave
    // the rest as is
    if (a.newTag && b.newTag) {
      const aVal = a.id ? a.id.toUpperCase() : ''
      const bVal = b.id ? b.id.toUpperCase() : ''
      if (aVal < bVal) {
        return -1
      } else if (aVal > bVal) {
        return 1
      } else {
        return 0
      }
    } else if (a.newTag) {
      return 1
    } else if (b.newTag) {
      return -1
    } else {
      return 0
    }
  })
  return data
}

const autocompleteCreateTag = function (params: SearchOptions): IdTextPair & NewTag | null {
  const term = $.trim(params.term)

  if (term === '') {
    return null
  }

  return {
    id: term,
    // text: `Other: ${term}`,
    text: term,
    newTag: true // add additional parameters
  }
}

export const initializeAutocompleteField = function (autocompleteEl: Element, modalParent?: HTMLElement, initializedCallback?: () => void, additionalOptions?: Select2Options): void {
  const $autocompleteEl = $(autocompleteEl)
  if ($autocompleteEl.hasClass('select2-hidden-accessible')) {
    // Select2 has been initialized
    return
  }

  const allowOther = $(autocompleteEl).data('allow-other')

  const additionalClass = []
  if (autocompleteEl.classList.contains('form-control-sm')) {
    additionalClass.push('select2-sm')
  }
  if ($(autocompleteEl).hasClass('is-invalid')) {
    additionalClass.push('is-invalid')
  }

  let allowClear = true
  if (autocompleteEl instanceof HTMLElement && autocompleteEl.dataset.allowClear == 'false') {
    allowClear = false
  }

  let autocompleteParams: Select2Options = {
    allowClear: allowClear,
    placeholder: '',
    containerCssClass: additionalClass.join(' '),
    // dropdownCssClass: additionalClass,
  }

  if (modalParent) {
    autocompleteParams['dropdownParent'] = $(modalParent)
  }

  if (allowOther) {
    autocompleteParams['tags'] = true
    if ($(autocompleteEl).data('tags') !== 'simple') {
      autocompleteParams['createTag'] = autocompleteCreateTag
      autocompleteParams['sorter'] = autocompleteResultsSorter
    }
  }

  const ajaxUrl = $(autocompleteEl).data('autocomplete-url')
  if (ajaxUrl !== 'undefined' && ajaxUrl != null) {
    if (autocompleteEl instanceof HTMLElement && autocompleteEl.dataset.autoFill == 'true') {
      autocompleteParams['minimumInputLength'] = 0
    } else {
      autocompleteParams['minimumInputLength'] = 2
    }

    autocompleteParams['ajax'] = {
      url: ajaxUrl,
      dataType: 'json',
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => undefined, failure?: () => undefined): void => {
        let requestUrl = settings.url
        if (requestUrl) {
          const requestData: any = settings.data
          if (requestData && requestData['term']) {
            const uri = new URI(requestUrl)
              uri.search({
                term: requestData['term']
              })
              requestUrl = uri.toString()
          }
          axios
            .get(requestUrl)
            .then((response) => {
              if (success) {
                success(response.data)
              }
            })
            .catch((_error) => {
              if (failure) {
                failure()
              }
            })
        }
      }
    }
  }

  if (additionalOptions) {
    autocompleteParams = Object.assign(autocompleteParams, additionalOptions)
  }

  autocompleteParams['width'] = '100%'
  const select2El = $(autocompleteEl).select2(autocompleteParams)
  if ($autocompleteEl.hasClass('form-control-sm') || $autocompleteEl.hasClass('custom-select-sm')) {
    select2El.data('select2').$container.addClass('autocomplete-sm')
  }
  if ($autocompleteEl.hasClass('is-invalid')) {
    select2El.data('select2').$container.addClass('is-invalid')
  }

  if (allowOther) {
    // this is required to persist tags after selecting other items after new tag was created
    $(select2El).on('change.select2', function (_e): void {
      $.each($(this).find('option[data-select2-tag]:selected'), function(idx, element): void {
        element.removeAttribute('data-select2-tag')
      })
    })
  }

  if (typeof initializedCallback === 'function') {
    initializedCallback()
  }
}

export const initializeFormFields = function (el: HTMLElement): void {
  // check if in modal
  const modalParent = el.closest('.modal-dialog') as HTMLElement

  // initialize autocomplete fields
  const autocompleteFields = el.querySelectorAll(':scope select.form-control.autocomplete, :scope select.form-control[data-autocomplete=true], :scope select.custom-select.autocomplete, :scope select.custom-select[data-autocomplete=true]')
  for (let i = 0; i < autocompleteFields.length; i++) {
    const autocompleteEl = autocompleteFields[i]
    if (autocompleteEl instanceof HTMLElement && autocompleteEl.dataset.autoInit != 'false') {
      initializeAutocompleteField(autocompleteEl, modalParent)
    }
  }

  // initialize date pickers
  const datePickerFields = el.querySelectorAll(':scope .date-picker.date')
  for (let i = 0; i < datePickerFields.length; i++) {
    const datePickerEl = datePickerFields[i]
    if (datePickerEl instanceof HTMLElement && datePickerEl.dataset.autoInit != 'false') {
      initializeDatePicker(datePickerEl)
    }
  }
}

export function initializeDatePicker (dpEl: Element, options?: { startDate?: Date | null; date?: Date; endDate?: Date | null }): JQuery<Element> | null {
  // const dateTogglerEl = dpEl.querySelector(':scope [ref="date-toggler"], :scope [_ref="date-toggler"]')
  let dateInputEl = null
  if (dpEl instanceof HTMLInputElement) {
    dateInputEl = dpEl
  } else {
    dateInputEl = dpEl.querySelector(':scope input[ref="date"], :scope input[_ref="date"]')
  }

  let startDate: Date | null = null
  let endDate: Date | null = null
  let date: Date | null = null
  if (options) {
    if (options.startDate) {
      startDate = options.startDate
    }
    if (options.endDate) {
      endDate = options.endDate
    }
    if (options.date) {
      date = options.date
    }
  }

  if (dateInputEl) {
    $(dateInputEl).datepicker({
      // trigger: dateTogglerEl,
      autoShow: false,
      autoHide: true,
      format: 'dd.mm.yyyy',
      weekStart: 1,
      zIndex: 2048, // otherwise will be hidden behind a modal
      startDate: startDate,
      endDate: endDate,
      date: date
    })

    return $(dateInputEl)
  }

  return null
}

export function autofocusInputField(containerEl: Element): void {
  const inputEl = containerEl.querySelector(':scope input:not([type="hidden"]), :scope select, :scope textarea')

  if (inputEl instanceof HTMLInputElement || inputEl instanceof HTMLSelectElement || inputEl instanceof HTMLTextAreaElement) {
    inputEl.focus()
  }
}
